import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { Stylist } from 'src/app/models';
import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export interface RetailTotalRow {
  timestamp: string;
  serviceCount: number;
  retailCount: number;
  retailQuantityAverage: number;
  retailPercentage: number;
  servicePercentage: number;

  serviceTotal: number;
  retailTotal: number;
  salesCount: number;
}

interface SalesReportStats {
  stats: List<any>;
  graph: any;
  timestamp: string;
}


export interface ClientTotalRow {
  timestamp: string;
  numOfClients: number;
  numOfNewClients: number;
}

export interface ClientRetention {
  totalNewClients: number;
  newClientsReturned: number;
  totalExistingClients: number;
  existingClientsReturned: number;
}

export interface ProductTotalRow {
  name: string;
  size?: string;
  category?: string;
  brand?: string;
  supplier?: string;
  numSold: number;
  total: number;
}

export interface ServiceTotalRow {
  id: number;
  name: string;
  category: string;
  numOfSold: number;
  total: number;
}

export interface IInventoryRow {
  productID: number,
  productName: string,
  productSize: string,
  brand: string,
  category: string,
  supplier: string,
  quantity: number,
  reorderPoint: number,
  cost: number,
  retailPrice: number,
}
export interface PaymentTotalRow {
  cashTotal: number;
  debitTotal: number;
  visaTotal: number;
  mastercardTotal: number;
  amexTotal: number;
  checkTotal: number;
  giftcardTotal: number;
  etransferTotal: number;
  stripeDebitTotal: number;
  stripeVisaTotal: number;
  stripeMastercardTotal: number;
  stripeAmexTotal: number;
  stripeDinersTotal: number;
  stripeDiscoverTotal: number;
  stripeJCBTotal: number;
  stripeUnionpayTotal: number;
  stripeUnkownTotal: number;
  stripePrePaidTotal: number;
}

export interface DiscountTotalRow {
  name: string;
  type: number;
  amount: number;
  usage: number;
}

export interface StylistCommissionLineitemlRow {
  id: number;
  date: Date;
  name: string;
  category: string;
  numOfSold: number;
  total: number;
  clientName: string;
  commission: number;
}

export interface StylistCommissionSummaryRow {
  total: number;
  stylistID: number;
  stylistName: string;
  serviceCommissions: number;
  retailCommissions: number;
}

export interface StylistSaleTipRow {
  uuid: string;
  datetime: Date;
  tipsTotal: number;
  isRefunded: number;
  clientName: string;
}

export interface StylistSaleTipSummaryRow {
  stylistID: number;
  stylistFullName: string;
  totalTips: number;
}

export interface SaleEstiamteRow {
  timestamp: string;
  numberOfServices: number;
  servicesTotalValue: number;
}

export interface PreBookingRow {
  timestamp: string;
  preBookedCount: number;
  clientCount: number;
}

export interface ProductivityRow {
  timestamp: string;
  booked: number;
  timeoff: number;
  totalWorkingHours: number;
}
export interface IBackbarRow {
  name: string;
  size: string;
  category: string;
  brand: string;
  supplier: string;
  count: number;

}

export interface IGiftCardRow {
  id: number;
  cardNumber: string;
  type: string;
  purchased: Date;
  lastActive: Date;
  source: string;
  purchaser: string;
  purchaserID: number;
  soldBy: string;
  soldByID: number;
  recipient: string;
  recipientID: number;
  original: number;
  current: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService extends SalonmonsterHttpClient {
 
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }
  public loadBackbarStats (salonID: number, startDate: Date, endDate: Date) : Observable<Array<IBackbarRow>> {
    return new Observable<Array<IBackbarRow>>((observer) => {
      let url = `${ENV_CONFIG.API_ROOT}/reports/${salonID}/backbar?startDate=${Utilities.formatDate(startDate)}&endDate=${Utilities.formatDate(endDate)}`;

      this.get(url)
        .pipe
          (map((data) => {
            const rows: IBackbarRow[] = [];

            for (const row of data) {
              rows.push(row);
            }
    
            return rows;
          })
        )
        .subscribe((rows: Array<IBackbarRow>) => {
          observer.next(rows);
          observer.complete();
        },
        (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        });

    });
  }

  public loadInventoryStats (salonID: number, currentDate: Date) : Observable<Array<IInventoryRow>> {
    return new Observable<Array<IInventoryRow>>((observer) => {
      let url = `${ENV_CONFIG.API_ROOT}/reports/${salonID}/inventory`;

      this.get(url)
        .pipe
          (map((data) => {
            const rows: IInventoryRow[] = [];

            for (const row of data) {
              rows.push(row);
            }
    
            return rows;
          })
        )
        .subscribe((rows: Array<IInventoryRow>) => {
          observer.next(rows);
          observer.complete();
        },
        (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        });

    });
  }
  public loadSalesStats (stylistID: number, startDate: Date, endDate: Date, groupBy: string = 'daily', stylistBreakdown?: boolean) : Observable<SalesReportStats> {
    return new Observable<SalesReportStats>((observer) => {
      let url = `${ENV_CONFIG.API_ROOT}/reports/${stylistID}/sales?startDate=${Utilities.formatDate(startDate)}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

      if (stylistBreakdown) {
        url += `&stylistBreakdown=true`;
      }

      this.get(url)
        .pipe
          (
            map((res: Response) => {
              const {stats, graph} = res[0];
              let reports: List<any> = List([]);


              for (let row of stats) {
                reports = reports.push(row);
              }

              return {stats: reports, graph};
            }),
            catchError((err: any) => 
            throwError( ()=> this.errorHandlerService.handleError(err))
          )
         )
        .subscribe({
          next:(reports: SalesReportStats) => {
          observer.next(reports);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
        });

    });
  }
  

  /*public loadSalesStats(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily',
    stylistBreakdown?: boolean
  ): Observable<List<any>> {
    let url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/sales?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    if (stylistBreakdown) {
      url += `&stylistBreakdown=true`;
    }

    return this.get(url).pipe(
      map((data) => {
        let reports: List<any> = List([]);

        for (const row of data) {
          reports = reports.push(row);
        }

        return reports;
      })
    );
  } */

  public loadRetailStats(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<RetailTotalRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/retail?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: RetailTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadClientsStats(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<ClientTotalRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/clients?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: ClientTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadClientRetention(stylistID: number): Observable<ClientRetention> {
    const url = `${ENV_CONFIG.API_ROOT}/reports/${stylistID}/clientretention`;

    return this.get(url).pipe(
      map((data) => {
        return {
          totalNewClients: data.totalNewClients,
          newClientsReturned: data.newClientsReturned,
          totalExistingClients: data.totalExistingClients,
          existingClientsReturned: data.existingClientsReturned
        };
      })
    );
  }

  public loadProductStats(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<ProductTotalRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/products?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: ProductTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadServiceStats(
    stylistID: number,
    startDate: Date,
    endDate: Date
  ): Observable<ServiceTotalRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/services?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: ServiceTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadPaymentStats(
    stylistID: number,
    startDate: Date,
    endDate: Date
  ): Observable<PaymentTotalRow> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/payments?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: PaymentTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        if (rows.length === 0) {
          throwError(new Error('Stats not found'));
        }

        return rows[0];
      })
    );
  }

  public loadDiscountStats(
    stylistID: number,
    startDate: Date,
    endDate: Date
  ): Observable<DiscountTotalRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/discounts?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: DiscountTotalRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadStylistCommissionStats(
    stylistID: number,
    startDate: Date,
    endDate: Date
  ): Observable<StylistCommissionLineitemlRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/commissions?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: StylistCommissionLineitemlRow[] = [];

        for (const row of data) {
          rows.push({
            id: row.id,
            date: Utilities.parseDate(row.date),
            name: row.name,
            category: row.category,
            numOfSold: row.numOfSold,
            total: row.total,
            clientName: row.clientName,
            commission: row.commission
          });
        }

        return rows;
      })
    );
  }

  public loadAllStaffCommissionStats(
    startDate: Date,
    endDate: Date
  ): Observable<StylistCommissionSummaryRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/0/commissions?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: StylistCommissionSummaryRow[] = [];

        for (const row of data) {
          const stylist = Stylist.parseStylistData(row.stylist);
          rows.push({
            total: row.serviceCommissions + row.retailCommissions,
            stylistID: stylist.id,
            stylistName: stylist.firstName + ' ' + stylist.lastName,
            serviceCommissions: row.serviceCommissions,
            retailCommissions: row.retailCommissions
          });
        }

        return rows;
      })
    );
  }

  public loadStylistTipsStats(
    stylistID: number,
    startDate: Date,
    endDate: Date
  ): Observable<StylistSaleTipRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/tips?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: StylistSaleTipRow[] = [];

        for (const row of data) {
          rows.push({
            uuid: row.uuid,
            datetime: Utilities.parseDate(row.datetime),
            tipsTotal: row.tipsTotal,
            isRefunded: row.isRefunded,
            clientName: row.clientName
          });
        }

        return rows;
      })
    );
  }

  public loadAllStaffTipsStats(
    startDate: Date,
    endDate: Date
  ): Observable<StylistSaleTipSummaryRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/0/tips?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: StylistSaleTipSummaryRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadSaleEstimates(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<SaleEstiamteRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/saleeestimates?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: SaleEstiamteRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadPreBooking(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<PreBookingRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/prebooking?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: PreBookingRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadProductivity(
    stylistID: number,
    startDate: Date,
    endDate: Date,
    groupBy: string = 'daily'
  ): Observable<ProductivityRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${stylistID}/productivity?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}&groupBy=${groupBy}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: ProductivityRow[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public loadGiftCardStats(salonID: number): Observable<IGiftCardRow[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/${salonID}/giftcard`;
    return this.get(url).pipe(
      map((data) => {
        const rows: IGiftCardRow[] = [];
        for (const row of data) {
          rows.push(row);
        }

        return rows;
      }))
  }
  
  public loadPaymentProcessingStats(
    startDate: Date,
    endDate: Date,
  ): Observable<any[]> {
    const url = `${
      ENV_CONFIG.API_ROOT
    }/reports/payment-processing?startDate=${Utilities.formatDate(
      startDate
    )}&endDate=${Utilities.formatDate(endDate)}`;

    return this.get(url).pipe(
      map((data) => {
        const rows: any[] = [];

        for (const row of data) {
          rows.push(row);
        }

        return rows;
      })
    );
  }

  public emailReports(exportArray: any[],
    outputFileName: string,
    cols: any[]): Observable<boolean>{
    const url = `${ENV_CONFIG.API_ROOT}/reports/email`;
    const user = this.userService.getUser();
    const email = user.email;
    const firstName = user.firstName;
    return this.post(url, {
      exportArray: exportArray,
      outputFileName: outputFileName,
      cols: cols,
      email: email,
      firstName: firstName
    }).pipe(
        map((data) => {
          return data.success
        })
      );
  }
}
