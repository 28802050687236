import { Cloneable } from "./cloneable";
import { Serializable } from "./serializable";
import { Lineitem } from './line-item/line-item.model';
import { Client } from './client/client.model';
import { Discount } from './discount/discount.model';
import { Stylist } from './stylist/stylist.model';
import { LineitemJSON } from './line-item/line-item-json.model';
import { List } from 'immutable';

export interface GiftCardJSON extends LineitemJSON {
    id: number,
    cardNumber: number,
    initialValue: number,
    currentValue: number,
    purchaseDate: Date,
    expirationDate: Date,
    status: number, // 0: active, 1: used, 2: expired
    soldBy: number,
    purchaser: number,
    recipient: number,
    cardType: number // 0: eGiftCard, 1: physical
    giftCardImageURL: string
}

export interface GiftCardSerialized {
    id: number,
    cardNumber: number,
    initialValue: number,
    currentValue: number,
    purchaseDate: Date,
    expirationDate: Date,
    status: number,
    soldBy: number,
    purchaser: number,
    recipient: number,
    cardType: number,
    giftCardImageURL: string
}

export class GiftCard extends Lineitem
  implements Cloneable<GiftCard, GiftCardJSON>, Serializable<GiftCardSerialized> {

  constructor(options: GiftCardJSON) {
    super(options);
    this.id = options.id;
    this.cardNumber = options.cardNumber;
    this.initialValue = options.initialValue;
    this.currentValue = options.currentValue;
    this.purchaseDate = options.purchaseDate;
    this.expirationDate = options.expirationDate;
    this.status = options.status;
    this.soldBy = options.soldBy;
    this.purchaser = options.purchaser;
    this.recipient = options.recipient;
    this.cardType = options.cardType;
    this.giftCardImageURL = options.giftCardImageURL;
    //lineitem
    this.lineItemID = options.lineItemID;
    this.clientID = options.clientID;
    this.client = options.client;
    this.type = options.type;
    this.name = options.name;
    this.price = options.price;
    this.quantity = options.quantity;
    this.isRefund = options.isRefund;
    this.stylistID = options.stylistID;
    this.stylist = options.stylist;
    this.discount = options.discount;
    this.taxRateType = options.taxRateType;
    this.customTaxes = options.customTaxes;
    this.isPhantom = options.isPhantom;
    this.preTaxTotals = 0;
  }


  public readonly id: number;
  public cardNumber: number;
  public initialValue: number;
  public currentValue: number;
  public purchaseDate: Date;
  public expirationDate: Date;
  public status: number;
  public soldBy: number;
  public purchaser: number;
  public recipient: number;
  public cardType: number;
  public giftCardImageURL: string;
  //lineitem
  public readonly lineItemID: number;
  public readonly clientID: number;
  public readonly client: Client;
  public readonly type: string;
  public name: string;
  public readonly price: number;
  public readonly quantity: number;
  public readonly isRefund: number;
  public readonly stylistID: number;
  public readonly stylist: Stylist;
  public readonly discount: Discount;
  public readonly taxRateType: number;
  public readonly customTaxes: List<string>;
  public readonly isPhantom: boolean;
  public preTaxTotals: number;

  public getID(): number {
    return this.id;
  }

  public setCustomTaxes(customTaxes: List<string>): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.customTaxes = customTaxes;
    return new GiftCard(data);
  }

  public setPhantom(isPhantom: boolean): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.isPhantom = isPhantom;
    return new GiftCard(data);
  }

  public setLineItemID(newID: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.lineItemID = newID;
    return new GiftCard(data);
  }

  public setClientID(clientID: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.clientID = clientID;
    return new GiftCard(data);
  }

  public setClient(client: Client): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.client = client;
    data.clientID = client.id;
    return new GiftCard(data);
  }

  public setRecipient(recipient: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.recipient = recipient;
    return new GiftCard(data);
  }

  public setType(type: string): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.type = type;
    return new GiftCard(data);
  }

  public setName(name: string): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.name = name;
    return new GiftCard(data);
  }

  public setPrice(price: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.price = price;
    return new GiftCard(data);
  }

  public setQuantity(quantity: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.quantity = quantity;
    return new GiftCard(data);
  }

  public setRefund(refund: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.isRefund = refund;
    return new GiftCard(data);
  }

  public setTaxRateType(taxRateType: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.taxRateType = taxRateType;
    return new GiftCard(data);
  }

  public setStylist(stylist: Stylist): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.stylistID = stylist.id;
    data.stylist = stylist;
    return new GiftCard(data);
  }

  public setGiftCardID(id: number): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.giftCardID = id;
    return new GiftCard(data);
  }

  public setDiscount(discount: Discount): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    discount = discount.setID(undefined);
    data.discount = discount;
    return new GiftCard(data);
  }

  public removeDiscount(): GiftCard {
    const data: GiftCardJSON = this.toJSON();
    data.discount = undefined;
    return new GiftCard(data);
  }

  public static parse(params: Object): GiftCard {
    return new GiftCard({
      id: params["id"],
      cardNumber: params["cardNumber"],
      initialValue: params["initialValue"],
      currentValue: params["currentValue"],
      purchaseDate: params["purchaseDate"],
      expirationDate: params["expirationDate"],
      status: params["status"],
      soldBy: params["soldBy"],
      purchaser: params["purchaser"],
      recipient: params["recipient"],
      cardType: params["cardType"],
      giftCardImageURL: params["giftCardImageURL"],
      price: params["initialValue"],
      lineItemID: undefined,
      clientID: undefined,
      client: undefined,
      type: 'giftCard',
      name: 'Gift card',
      quantity: 1,
      isRefund: 0,
      taxRateType: 0,
      stylist: undefined,
      stylistID: undefined,
      discount: undefined,
      customTaxes: List.of<string>(),
      isPhantom: false,
    });
  }

  public toJSON(): GiftCardJSON {
    return Object.assign(super.toJSON(), {
      id: this.id,
      cardNumber: this.cardNumber,
      initialValue: this.initialValue,
      currentValue: this.currentValue,
      purchaseDate: this.purchaseDate,
      expirationDate: this.expirationDate,
      status: this.status,
      soldBy: this.soldBy,
      purchaser: this.purchaser,
      recipient: this.recipient,
      cardType: this.cardType,
      giftCardImageURL: this.giftCardImageURL
    });
  }

  public clone(): GiftCard {
    return new GiftCard(this.toJSON());
  }

  public serialize(): GiftCardSerialized {
    return {
        id: this.id,
        cardNumber: this.cardNumber,
        initialValue: this.initialValue,
        currentValue: this.currentValue,
        purchaseDate: this.purchaseDate,
        expirationDate: this.expirationDate,
        status: this.status,
        soldBy: this.soldBy,
        purchaser: this.purchaser,
        recipient: this.recipient,
        cardType: this.cardType,
        giftCardImageURL: this.giftCardImageURL
    };
  }
}
