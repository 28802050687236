import { Client } from '../client/client.model';
import { Cloneable } from '../cloneable.model';
import { Discount } from '../discount/discount.model';
import { Lineitem } from '../line-item/line-item.model';
import { List } from 'immutable';
import { Payment } from '../payment/payment.model';
import { Product } from '../product/product.model';
import { SaleJSON } from './sale-json.model';
import { SaleSerialized } from './sale-serialized.model';
import { Serializable } from '../serializable.model';
import { Service } from '../service/service.model';
import { Stylist } from '../stylist/stylist.model';
import { Tax } from '../tax/tax.model';
import { Tip } from '../tip/tip.model';
import { Utilities } from 'src/app/utilities/utilities';
import { GiftCard } from '..';

export interface SaleCheckOutCallBackData {
  sale: Sale;
  action: string;
}

export class Sale
  implements Cloneable<Sale, SaleJSON>, Serializable<SaleSerialized>
{
  constructor(options: SaleJSON) {
    this.id = options.id;
    this.uuid = options.uuid;
    this.client = options.client;
    this.datetime = options.datetime;
    this.dateModified = options.dateModified;
    this.saleEditParent = options.saleEditParent;
    this.saleRefundParent = options.saleRefundParent;
    this.cashRounding = options.cashRounding;
    this.lineitems = options.lineitems;
    this.status = options.status;
    this.taxes = options.taxes;
    this.tips = options.tips;
    this.payments = options.payments;
    this.stylist = options.stylist;
    this.history = options.history || List([]);
    this.cachedTotalsFromServer = options.cachedTotalsFromServer;
    this.cachedClientsFromServer = options.cachedClientsFromServer;
    this.cachedStylistsFromServer = options.cachedStylistsFromServer;

    // find the clients of this sale
    let clientIDs: List<number> = List([this.client.id]);
    let clients: List<Client> = List([this.client]);
    this.lineitems.forEach((lineitem: Lineitem) => {
      if (
        lineitem.client &&
        clientIDs.indexOf(lineitem.client.getID()) === -1
      ) {
        clients = clients.push(lineitem.client);
        clientIDs = clientIDs.push(lineitem.client.getID());
      }
    });
    this.saleClients = clients;

    // pre-tax totals of lineitems
    let subTotal = 0;
    let tipsTotal = 0;
    let paymentsTotal = 0;
    let updatedTaxes: List<Tax> = this.taxes;

    // reset tax.totals
    updatedTaxes = updatedTaxes.update((taxes) => {
      return taxes
        .map((tax: Tax) => {
          return tax.setTotals(0);
        })
        .toList();
    });

    // loop through lineitems and calculate taxes
    this.lineitems.forEach((lineitem: Lineitem) => {
      const lineItemPreTaxTotals = lineitem.getPreTaxTotals();

      // check if lineitem is a refund
      // if (lineitem.isItemRefund()) {
      // lineItemPreTaxTotals = lineitem.getPreTaxTotals() * -1;
      // }

      subTotal += lineItemPreTaxTotals;

      // calculate tax
      if (lineitem.getTaxRateType() === 0) {
        // use normal sale taxes
        updatedTaxes = updatedTaxes.update((taxes) => {
          return taxes
            .map((tax: Tax) => {
              if (
                // applies to both services or products
                tax.appliesTo === 2 ||
                // apply tax to services only
                (tax.appliesTo === 0 && lineitem.getType() === 'service') ||
                // apply to products only
                (tax.appliesTo === 1 && lineitem.getType() === 'product')
              ) {
                let taxTotals: number = tax.totals;
                if( lineitem.getType() !== 'giftCard') {
                  taxTotals += Utilities.roundTo2Decimal(
                    lineItemPreTaxTotals * tax.rate
                  );
                }
                taxTotals = Utilities.roundTo2Decimal(taxTotals);
                return tax.setTotals(taxTotals);
              }

              return tax;
            })
            .toList();
        });
      } else if (lineitem.getTaxRateType() === 2) {
        // custom taxes
        lineitem.getCustomTaxes().forEach((customTaxName: string) => {
          updatedTaxes = updatedTaxes.update((taxes) => {
            return taxes
              .map((tax: Tax) => {
                // skip
                if (tax.name !== customTaxName) {
                  return tax;
                }

                // if (

                // // applies to both services or products
                // (tax.appliesTo === 2) ||

                // // apply tax to services only
                // (tax.appliesTo === 0 && lineitem.getType() === 'service') ||

                // // apply to products only
                // (tax.appliesTo === 1 && lineitem.getType() === 'product')

                // ) {

                let taxTotals: number = tax.totals;
                taxTotals += Utilities.roundTo2Decimal(
                  lineItemPreTaxTotals * tax.rate
                );
                taxTotals = Utilities.roundTo2Decimal(taxTotals);

                return tax.setTotals(taxTotals);
                // }

                // return tax;
              })
              .toList();
          });
        });
      }
    });

    this.taxes = updatedTaxes;

    let taxesTotal = 0;
    this.taxes.forEach((t) => {
      taxesTotal += t.totals;
    });
    this.taxesTotal = taxesTotal;

    // loop through tips
    this.tips.forEach((tip: Tip) => {
      if (tip.isRefunded === 1) {
        tipsTotal -= Utilities.roundTo2Decimal(tip.value);
      } else {
        tipsTotal += Utilities.roundTo2Decimal(tip.value);
      }
    });

    // loop through payments
    this.payments.forEach((payment: Payment) => {
      paymentsTotal += Utilities.roundTo2Decimal(payment.amount);
    });

    this.subTotal = Utilities.roundTo2Decimal(subTotal);
    this.lineItemsAfterTaxTotal = subTotal;

    // apply taxes
    let totalTaxes = 0;
    this.taxes.forEach((tax: Tax) => {
      totalTaxes += tax.totals;
    });

    this.lineItemsAfterTaxTotal = this.lineItemsAfterTaxTotal + totalTaxes;
    this.lineItemsAfterTaxTotal = Utilities.roundTo2Decimal(
      this.lineItemsAfterTaxTotal
    );
    this.tipsTotal = Utilities.roundTo2Decimal(tipsTotal);
    // this.total = Utilities.roundTo2Decimal(this.lineItemsAfterTaxTotal + this.tipsTotal + this.cashRounding);
    this.total = Utilities.roundTo2Decimal(
      this.lineItemsAfterTaxTotal + this.tipsTotal
    );
    this.paymentsTotal = Utilities.roundTo2Decimal(paymentsTotal);
    this.balance =
      Utilities.roundTo2Decimal(this.paymentsTotal - this.total) * -1;
  }
  public readonly id: number;

  public readonly uuid: string;

  public readonly salonID: number;

  public readonly client: Client;

  public readonly stylistID: number;

  public readonly stylist: Stylist;

  public readonly datetime: Date;

  public readonly dateModified: Date;

  public readonly lineitems: List<Lineitem>;

  public readonly status: number;

  public readonly saleEditParent: number;

  public readonly saleRefundParent: number;

  public readonly cashRounding: number;

  public readonly taxes: List<Tax>;

  public readonly tips: List<Tip>;

  public readonly payments: List<Payment>;

  public readonly subTotal: number;

  public readonly lineItemsAfterTaxTotal: number;

  public readonly taxesTotal: number;

  public readonly tipsTotal: number;

  public readonly paymentsTotal: number;

  public readonly total: number;

  public readonly balance: number;

  public readonly cachedTotalsFromServer: number;

  public readonly cachedClientsFromServer: string;

  public readonly cachedStylistsFromServer: string;

  public readonly history: List<Sale>;

  public readonly saleClients: List<Client>;

  public static parseSaleData(saleData): Sale {
    let lineitems: List<Lineitem> = List([]);
    let taxes: List<Tax> = List([]);
    let tips: List<Tip> = List([]);
    let payments: List<Payment> = List([]);

    // const saleClientID = saleData.client.id;
    const client: Client = Client.parseClientData(saleData.client);
    const stylist: Stylist = Stylist.parseStylistData(saleData.stylist);

    for (const lineitem of saleData.lineitems) {
      let discount: Discount;
      let item: Lineitem;
      const clientID =
        lineitem['clientID'] === undefined || lineitem['clientID'] === null
          ? 0
          : lineitem['clientID'];

      if (lineitem['discount'] !== undefined && lineitem['discount'] !== null) {
        discount = new Discount({
          id: lineitem.discount.id,
          name: lineitem.discount.name,
          type: lineitem.discount.type,
          amount: lineitem.discount.amount,
          discountCommission: lineitem.discount.discountCommission,
        });
      }

      const options = {
        id:
          lineitem['type'] === 'service'
            ? lineitem['serviceID']
            : lineitem['productID'],
        lineItemID: lineitem['id'],
        clientID: clientID,
        client: Client.parseClientData(lineitem.client),
        type: lineitem['type'],
        name: lineitem['name'],
        price: lineitem['price'],
        quantity: lineitem['quantity'],
        isRefund: lineitem['isRefund'],
        stylistID: lineitem['stylistID'],
        stylist: Stylist.parseStylistData(lineitem.stylist),
        discount: discount,
        taxRateType: lineitem['taxRateType'],
        customTaxes: lineitem['customTaxes'],

        // apply bogus data for Service Options
        startDateTime: new Date(),
        bookingGroupID: lineitem['bookingGroupID'],
        serviceDefinition: undefined,
        clientNotes: undefined,
        stylistNotes: undefined,
        isPhantom: false,
        durations: {
          startDuration: 60,
          processDuration: 0,
          finishDuration: 0,
        },

        // for products
        categoryID: undefined,
        category: undefined,
        supplierID: undefined,
        supplier: undefined,
        brandID: undefined,
        brand: undefined,
        description: undefined,
        stock: undefined,
        reorder: undefined,
        cost: undefined,
        retail: undefined,
        size: undefined,
        markupType: undefined,
        markup: undefined,
        wholesale: undefined,
        supplierCode: undefined,
        productCode: undefined,
        commissionType: undefined,
        commissionDistributionType: undefined,
        commissionValue: undefined,
        productSalonTaxIDs: undefined,
        productLevelCommissions: undefined,
        trackInventory: undefined,
        reorderAmount: undefined,
        reorderPoint: undefined,
        imageURL: undefined,

        //for gift card
        giftCardID: lineitem['giftCardID'],
        cardNumber: lineitem['cardNumber'],
        initialValue:  lineitem['initialValue'],
        currentValue:  lineitem['currentValue'],
        purchaseDate: lineitem['purchaseDate'],
        expirationDate:  lineitem['expirationDate'],
        status:  lineitem['status'],
        soldBy:  lineitem['soldBy'],
        purchaser: lineitem['purchaser'],
        recipient:  lineitem['recipient'],
        cardType: lineitem['cardType'],
        giftCardImageURL:  lineitem['giftCardImageURL'],
      };

      if (lineitem['type'] === 'service') {
        item = new Service(options);
      } else if (lineitem['type'] === 'product') {
        item = new Product(options);
      } else if (lineitem['type'] === 'giftCard') {
        item = new GiftCard(options);
      }

      console.log("parse sale", item)
      lineitems = lineitems.push(item);
    }

    for (const tax of saleData.taxes) {
      taxes = taxes.push(
        new Tax({
          id: tax['id'],
          name: tax['name'],
          rate: tax['rate'],
          appliesTo: tax['appliesTo'],
          bizNo: tax['bizNo'],
          totals: 0,
        })
      );
    }

    for (const tip of saleData.tips) {
      tips = tips.push(
        new Tip({
          id: tip['id'],
          stylistID: tip['stylistID'],
          value: tip['amount'],
          isRefunded: tip['isRefunded'],
          stylist: Stylist.parseStylistData(tip['stylist']),
        })
      );
    }

    for (const payment of saleData.payments) {
      payments = payments.push(
        new Payment({
          id: payment['id'],
          type: payment['type'],
          amount: payment['amount'],
          name: payment['name'],
          intentID: payment['intentID'],
          last4Digits: payment['last4Digits'],
          expDate: payment['expDate'],
          chargeID: payment['chargeID'],
          isInterac: payment['isInterac'],
          giftCardID: payment['giftCardID'],
          applicationFee: payment['applicationFee'],
        })
      );
    }

    return new Sale({
      id: saleData['id'],
      uuid: saleData['uuid'],
      client: client,
      datetime: Utilities.parseDate(saleData['datetime']),
      dateModified: Utilities.parseDate(saleData['dateModified']),
      status: saleData['status'],
      saleEditParent: saleData['saleEditParent'],
      saleRefundParent: saleData['saleRefundParent'],
      cashRounding: saleData['cashRounding'],
      lineitems: lineitems,
      taxes: taxes,
      tips: tips,
      payments: payments,
      stylist: stylist,
      history: List([]),
      cachedTotalsFromServer: saleData['total'],
      cachedClientsFromServer: saleData['clients'],
      cachedStylistsFromServer: saleData['stylists'],
    });
  }

  public static getPaymentTypes(): List<Payment> {
    return List([
      new Payment({ type: 1, name: 'Cash', amount: 0 }),
      new Payment({ type: 2, name: 'Debit', amount: 0 }),
      new Payment({ type: 3, name: 'Visa', amount: 0 }),
      new Payment({ type: 4, name: 'MasterCard', amount: 0 }),
      new Payment({ type: 5, name: 'AMEX', amount: 0 }),
      new Payment({ type: 6, name: 'Check/Cheque', amount: 0 }),
      new Payment({ type: 7, name: 'Giftcard', amount: 0 }),
      new Payment({ type: 8, name: 'e-Transfer', amount: 0 }),
      new Payment({ type: 11, name: 'Square', amount: 0 }),
    ]);
  }

  public static getPaymentTypesWithStripeAccount(): List<Payment> {
    return List([
      new Payment({ type: 1, name: 'Cash', amount: 0 }),
      new Payment({ type: 7, name: 'Giftcard', amount: 0 }),
      new Payment({ type: 11, name: 'Square', amount: 0 }),
      new Payment({ type: 25, name: 'External payment', amount: 0 })
    ]);
  }

  private generateUniqueLineItemID(): number {
    let token = 1;

    while (true) {
      let unique = true;

      // loop through all lineitems to see if this token has been already used
      const result: List<Lineitem> = this.lineitems
        .filter((lineitem) => lineitem.getLineItemID() === token)
        .toList();

      if (result.count()) {
        unique = false;
      }

      if (unique) {
        return token;
      } else {
        // generate another token
        token++;
      }
    }
  }

  public findLineItemIndex(lineItemToFind: Lineitem): number {
    let index = 0;
    let found = false;
    this.lineitems.some((lineitem: Lineitem) => {
      if (lineItemToFind.getLineItemID() === lineitem.getLineItemID()) {
        found = true;
        return true;
      }

      index++;
      return false;
    });

    if (found) {
      return index;
    } else {
      return -1;
    }
  }

  public setLineItemDiscount(lineitem: Lineitem, discount: Discount): Sale {
    const data: SaleJSON = this.toJSON();
    data.lineitems = data.lineitems.update(
      this.findLineItemIndex(lineitem),
      (lineitem: Lineitem) => {
        return lineitem.setDiscount(discount);
      }
    );

    return new Sale(data);
  }

  public removeLineItemDiscount(lineitem: Lineitem): Sale {
    const data: SaleJSON = this.toJSON();
    data.lineitems = data.lineitems.update(
      this.findLineItemIndex(lineitem),
      (lineitem: Lineitem) => {
        return lineitem.removeDiscount();
      }
    );

    return new Sale(data);
  }

  public setLineItemQuantity(lineitem: Lineitem, quantity: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.lineitems = data.lineitems.update(
      this.findLineItemIndex(lineitem),
      (lineitem: Lineitem) => {
        return lineitem.setQuantity(quantity);
      }
    );

    return new Sale(data);
  }

  public setLineItemPrice(lineitem: Lineitem, price: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.lineitems = data.lineitems.update(
      this.findLineItemIndex(lineitem),
      (lineitem: Lineitem) => {
        return lineitem.setPrice(price);
      }
    );

    return new Sale(data);
  }

  public updateLineItem(lineitem: Lineitem): Sale {
    const index = this.findLineItemIndex(lineitem);

    if (index === -1) {
      return this.addLineItem(lineitem);
    } else {
      const data: SaleJSON = this.toJSON();
      data.lineitems = data.lineitems.set(index, lineitem);
      return new Sale(data);
    }
  }

  public addLineItem(lineitem: Lineitem): Sale {
    const data: SaleJSON = this.toJSON();

    // create a temporary ID for this lineitem
    lineitem = lineitem.setLineItemID(this.generateUniqueLineItemID());

    // importatn, forcce new lineitem to be Phsantom
    lineitem = lineitem.setPhantom(true);

    data.lineitems = data.lineitems.push(lineitem);
    return new Sale(data);
  }

  public removeLineItem(lineitemToBeDeleted: Lineitem): Sale {
    if (lineitemToBeDeleted.constructor === Service) {
      // cast this lineitem to a Service so we can grab the bokingGroupID to be deleted
      const service: Service = lineitemToBeDeleted as Service;
      const bookingGroupID = service.getBookingGroupID();

      // delete all services that are in the same bookingGroup
      return this.removeLineItemsByBookingGroupID(bookingGroupID);
    } else {
      // product
      const data: SaleJSON = this.toJSON();
      data.lineitems = data.lineitems.remove(
        this.findLineItemIndex(lineitemToBeDeleted)
      );
      return new Sale(data);
    }
  }

  public removeLineItemsByBookingGroupID(bookingGroupID: number): Sale {
    const data: SaleJSON = this.toJSON();
    let index = 0;
    data.lineitems.forEach((lineitem: Lineitem) => {
      if (lineitem.constructor === Service) {
        const service = lineitem as Service;

        if (service.getBookingGroupID() === bookingGroupID) {
          data.lineitems = data.lineitems.remove(index);
          index--;
        }
      }

      index++;
    });

    return new Sale(data);
  }

  public removeLineItemsByBookingID(id: number): Sale {
    const data: SaleJSON = this.toJSON();
    let index = 0;
    data.lineitems.forEach((lineitem: Lineitem) => {
      if (lineitem.constructor === Service) {
        const service = lineitem as Service;
        if (service.id === id) {
          data.lineitems = data.lineitems.remove(index);
          index--;
        }
      }

      index++;
    });

    return new Sale(data);
  }


  public removeLineItemsByClientID(clientID: number): Sale {
    const data: SaleJSON = this.toJSON();
    let index = 0;
    data.lineitems.forEach((lineitem: Lineitem) => {
      if (lineitem.getClientID() === clientID) {
        data.lineitems = data.lineitems.remove(index);
        index--;
      }

      index++;
    });

    return new Sale(data);
  }

  public getTips(): List<Tip> {
    return this.tips;
  }

  public addTip(tip: Tip): Sale {
    const data: SaleJSON = this.toJSON();
    data.tips = data.tips.push(tip.setID(undefined));
    return new Sale(data);
  }

  public updateTip(index: number, tip: Tip): Sale {
    const data: SaleJSON = this.toJSON();
    data.tips = data.tips.set(index, tip);
    return new Sale(data);
  }

  public removeTip(index: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.tips = data.tips.remove(index);
    return new Sale(data);
  }

  public addPayment(payment: Payment): Sale {
    const data: SaleJSON = this.toJSON();
    data.payments = data.payments.push(payment.setID(undefined));
    return new Sale(data);
  }

  public editPaymentAmount(index: number, amount: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.payments = data.payments.update(index, (payment) => {
      return payment.setAmount(amount);
    });
    return new Sale(data);
  }

  public updatePayment(index: number, payment: Payment): Sale {
    const data: SaleJSON = this.toJSON();
    data.payments = data.payments.set(index, payment);
    return new Sale(data);
  }

  public editPaymentType(index: number, type: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.payments = data.payments.update(index, (payment) => {
      return payment.setType(type);
    });
    return new Sale(data);
  }

  public getPayments(): List<Payment> {
    return this.payments;
  }

  public removePayment(index: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.payments = data.payments.remove(index);
    return new Sale(data);
  }

  public getID(): number {
    return this.id;
  }

  public setID(id: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.id = id;
    return new Sale(data);
  }

  public getUUID(): string {
    return this.uuid;
  }

  public setUUID(uuid: string): Sale {
    const data: SaleJSON = this.toJSON();
    data.uuid = uuid;
    return new Sale(data);
  }

  public getSalonID(): number {
    return this.salonID;
  }

  public setClient(client: Client): Sale {
    const data: SaleJSON = this.toJSON();
    data.client = client;
    return new Sale(data);
  }

  public getStylistID(): number {
    return this.stylistID;
  }

  public setStylistID(stylist: Stylist): Sale {
    const data: SaleJSON = this.toJSON();
    data.stylist = stylist;
    return new Sale(data);
  }

  public getStylist(): Stylist {
    return this.stylist;
  }

  public getDateTime(): Date {
    return this.datetime;
  }

  public getStartDateTimeAsString(): string {
    return Utilities.formatDate(this.getDateTime(), 'HH:mm');
  }

  public setDateTime(datetime: Date): Sale {
    const data: SaleJSON = this.toJSON();
    data.datetime = datetime;
    return new Sale(data);
  }

  public getStatus(): number {
    return this.status;
  }

  public setStatus(status: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.status = status;
    return new Sale(data);
  }

  public isRefundSale(): boolean {
    return (
      this.getSaleRefundParent() !== undefined &&
      this.getSaleRefundParent() !== null
    );
  }

  public isEditMode(): boolean {
    return (
      this.getSaleEditParent() !== undefined &&
      this.getSaleEditParent() !== null
    );
  }

  public getSaleRefundParent(): number {
    return this.saleRefundParent;
  }

  public setSaleRefundParent(saleID: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.saleRefundParent = saleID;
    return new Sale(data);
  }

  public getSaleEditParent(): number {
    return this.saleEditParent;
  }

  public setSaleEditParent(saleID: number): Sale {
    const data: SaleJSON = this.toJSON();
    data.saleEditParent = saleID;
    return new Sale(data);
  }

  // public setCashRounding (cashRounding: number) : Sale {
  //   const data: SaleJSON = this.toJSON();
  //   data.cashRounding = cashRounding;
  //   return new Sale(data);
  // }

  public getLineItems(): List<Lineitem> {
    return this.lineitems;
  }

  public getSubTotal(): number {
    return this.subTotal;
  }

  public getlineItemsAfterTaxTotal(): number {
    return this.lineItemsAfterTaxTotal;
  }

  public getTotal(): number {
    return this.total;
  }

  public getPaymentsTotal(): number {
    return this.paymentsTotal;
  }

  public getTaxes(): List<Tax> {
    return this.taxes;
  }

  public getClient(): Client {
    return this.client;
  }

  public getHistory(): List<Sale> {
    return this.history;
  }

  public isSaleReadyForCheckOut(): boolean {
    let containsCash = false;
    this.payments.forEach((payment) => {
      if (payment.type === 1) {
        containsCash = true;
      }
    });

    if (containsCash) {
      // we need to be a little forgiving here (cash rounding)
      // wec can allow <= 2 cents
      return Math.abs(this.balance) <= 2;
    } else {
      return this.balance === 0;
    }
  }

  public addHistory(sale: Sale): Sale {
    const data: SaleJSON = this.toJSON();
    data.history = data.history.push(sale);
    return new Sale(data);
  }

  public toJSON(): SaleJSON {
    return {
      id: this.id,
      uuid: this.uuid,
      client: this.client,
      stylist: this.stylist,
      datetime: this.datetime,
      dateModified: this.dateModified,
      lineitems: this.lineitems,
      status: this.status,
      taxes: this.taxes,
      tips: this.tips,
      payments: this.payments,
      saleEditParent: this.saleEditParent,
      saleRefundParent: this.saleRefundParent,
      cashRounding: this.cashRounding,
      history: this.history,
      cachedTotalsFromServer: this.cachedTotalsFromServer,
    };
  }

  public clone(): Sale {
    return new Sale(this.toJSON());
  }

  public serialize(): SaleSerialized {
    const payload: SaleSerialized = {
      id: this.getID(),
      stylistID: this.getStylistID(), // TODO: update this to the current user who edited this
      datetime: Utilities.formatDate(this.getDateTime()),
      status: this.getStatus(),
      clientID: this.getClient() ? this.getClient().id : undefined,
      lineitems: [],
      tips: [],
      payments: [],
      saleEditParent: this.saleEditParent,
      saleRefundParent: this.saleRefundParent,
      cashRounding: this.cashRounding,
    };

    this.lineitems.forEach((lineitem: Lineitem) => {
      const item = {
        id: undefined,
        price: lineitem.getPrice(),
        quantity: lineitem.getQuantity(),
        clientID:
          lineitem.getClient().getID() !== undefined &&
            lineitem.getClient().getID() !== null &&
            lineitem.getClient().getID() !== 0
            ? lineitem.getClient().getID()
            : null,
        stylistID: lineitem.getStylist().getID(),
        isRefund: lineitem.isItemRefund() ? 1 : 0,
        type: undefined,
        serviceID: undefined,
        productID: undefined,
        giftCardID: undefined,
        serviceDefinitionID: undefined,
        discount: undefined,
        taxRateType: lineitem.taxRateType,

        cardNumber: undefined,
        initialValue: undefined,
        currentValue: undefined,
        purchaseDate: undefined,
        expirationDate: undefined,
        status: undefined,
        soldBy: undefined,
        purchaser: undefined,
        recipient: undefined,
        cardType: undefined,
        giftCardImageURL: undefined
      };

      if (!lineitem.phantom()) {
        item.id = lineitem.getLineItemID();
      }

      if (lineitem.getType() === 'service') {
        const service: Service = lineitem as Service;

        // check if this is an upsell booking
        if (service.getId() !== undefined && service.getId() !== null) {
          item.serviceID = service.getId();
        } else {
          item.serviceDefinitionID = service.getServiceDefinition().getID();
        }

        item.type = 'service';
      } else if (lineitem.getType() === 'product') {
        const product: Product = lineitem as Product;
        item.productID = product.getID();
        item.type = 'product';
      } else if (lineitem.getType() === 'giftCard') {
        const giftCard: GiftCard = lineitem as GiftCard;
        item.type = 'giftCard';
        item.giftCardID = giftCard.id;
        item.cardNumber = giftCard.cardNumber;
        item.initialValue = giftCard.initialValue;
        item.currentValue = giftCard.currentValue;
        item.purchaseDate = giftCard.purchaseDate;
        item.expirationDate = giftCard.expirationDate;
        item.status = giftCard.status;
        item.soldBy = giftCard.soldBy;
        item.purchaser = giftCard.purchaser;
        item.recipient = giftCard.recipient;
        item.cardType = giftCard.cardType;
        item.giftCardImageURL = giftCard.giftCardImageURL;
      } else {
        throw new Error('Invalid lineitem type');
      }

      if (
        lineitem.getDiscount() !== undefined &&
        lineitem.getDiscount() !== null
      ) {
        item.discount = lineitem.getDiscount().serialize();
      }

      if (lineitem.taxRateType === 2) {
        item['customTaxes'] = lineitem.customTaxes;
      }

      payload.lineitems.push(item);
    });

    this.tips.forEach((tip) => {
      payload.tips.push({
        id: tip.id,
        amount: tip.value,
        stylistID: tip.stylistID,
        isRefunded: tip.isRefunded,
      });
    });

    this.payments.forEach((payment: Payment) => {
      payload.payments.push({
        id: payment.id,
        amount: payment.amount,
        type: payment.type,
        intentID: payment.intentID,
        last4Digits: payment.last4Digits,
        expDate: payment.expDate,
        chargeID: payment.chargeID,
        isInterac: payment.isInterac,
        giftCardID: payment.giftCardID,
        applicationFee: payment.applicationFee
      });
    });

    return payload;
  }
}
