import { CommunicationJSON } from './communication-json.model';
import { Utilities } from 'src/app/utilities/utilities';;

export class Communication {
  public readonly id: number;

  public readonly datetime: Date;

  public readonly clientID: number;

  public readonly title: string;

  public readonly message: string;

  public readonly status: number;

  public readonly clientEmail: string;

  public readonly clientPhone: string;

  public readonly sender: string;

  public readonly communicationType: string;

  public readonly emailCommunicationType: number;

  constructor(options?: CommunicationJSON) {
    options = options || {};
    this.id = options.id;
    this.datetime = options.datetime;
    this.clientID = options.clientID;
    this.title = options.title;
    this.message = options.message;
    this.status = options.status;
    this.clientEmail = options.clientEmail;
    this.clientPhone = options.clientPhone;
    this.sender = options.sender;
    this.communicationType = options.communicationType;
    this.emailCommunicationType = options.emailCommunicationType;
  }

  public static parse(params: Object): Communication {
    return new Communication({
      id: params['id'],
      datetime: params['datetime'],
      clientID: params['clientID'],
      title: params['title'],
      message: params['message'],
      status: params['status'],
      clientEmail: params['clientEmail'],
      clientPhone: params['clientPhone'],
      sender: params['sender'],
      communicationType: params['communicationType'],
      emailCommunicationType: params['emailCommunicationType']
    });
  }

  public getId(): number {
    return this.id;
  }

  public getDateTime(): Date {
    return this.datetime;
  }
  public getDateISO(): string {
    const utcDatetimeString = this.datetime; 
    const utcDateTime = new Date(utcDatetimeString);
    const localDateTimeString = utcDateTime.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    return localDateTimeString;
  }

  public getClientId(): number {
    return this.clientID;
  }

  public getTitle(): string {
    return this.title;
  }

  public getMessage(): string {
    return this.message;
  }

  public getStatus(): number {
    return this.status;
  }

  public getClientEmail(): string {
    return this.clientEmail;
  }

  public getClientPhone(): string {
    return this.clientPhone;
  }

  public getSender(): string {
    return this.sender;
  }

  public getCommunicationType(): string {
    return this.communicationType;
  }

  public getEmailCommunicationType(): number {
    return this.emailCommunicationType;
  }

  public getTimeElapsedInMinutes(): number {
    return Utilities.timeDiffInMinutes(this.getDateTime(), new Date());
  }

  public getFormattedTimeElapsed(): string {
    // let elapsed: number = this.getTimeElapsedInMinutes();
    const years: number = Utilities.timeDiffInYears(
      this.getDateTime(),
      new Date()
    );
    const months: number = Utilities.timeDiffInMonths(
      this.getDateTime(),
      new Date()
    );
    const days: number = Utilities.daysDiff(this.getDateTime(), new Date());
    const hours: number = Utilities.timeDiffInHours(
      this.getDateTime(),
      new Date()
    );
    const minutes: number = Utilities.timeDiffInMinutes(
      this.getDateTime(),
      new Date()
    );

    if (years > 1) {
      return `${Math.floor(years)} ${years > 1 ? 'years' : 'year'} ago`;
    } else if (months > 1) {
      return `${Math.floor(months)} ${months > 1 ? 'months' : 'month'} ago`;
    } else if (days > 1) {
      return `${Math.floor(days)} ${days > 1 ? 'days' : 'day'} ago`;
    } else if (hours > 1) {
      return `${Math.floor(hours)} ${hours > 1 ? 'hours' : 'hour'} ago`;
    } else if (minutes > 1) {
      return `${Math.floor(minutes)} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
    } else {
      return '';
    }

    // if (elapsed < 60) {
    //   return elapsed + ' minutes ago';
    // } else {
    //   const days: number = elapsed / (24*60);
    //   const hours: number = Math.floor(days % 60);
    //   const minutes: number = days % 60;

    // return `${days} ${days > 0 ? 'days' : 'day'} ${hours} ${hours > 1 ? 'hours' : 'hour'} and ${minutes} ${minutes > 0 ? 'minutes' : 'minute'} ago`;
    // }
  }
}
