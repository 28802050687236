import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from './error-handler.service';
import { Injectable } from '@angular/core';
import { List } from 'immutable';
import { Observable } from 'rxjs';
import { RegisterClosure } from 'src/app/models';
import { SalonmonsterHttpClient } from '../services/salonmonster-http-client';
import { UserService } from '../services/user.service';
import { Utilities } from '../utilities/utilities';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegisterClosureService extends SalonmonsterHttpClient {
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public getDateOpened () :Observable<any>{
    const url = `${ENV_CONFIG.API_ROOT}/registerclosures-dateopened`;
    return this.get(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  public getSalonRegisterClosures(startDate, endDate): Observable<List<RegisterClosure>> {
    const url = `${ENV_CONFIG.API_ROOT}/registerclosures?startDate=${Utilities.formatDate(startDate)}&endDate=${Utilities.formatDate(endDate)}`;
    return this.get(url).pipe(
      map((data) => {
        let registerClosures: List<any> = List([]);
        for (const registerClosureData of data) {
          registerClosures = registerClosures.push(
            registerClosureData
          );
        }

        return registerClosures;
      })
    );
  }

  public loadRegisterClosure(id: number): Observable<RegisterClosure> {
    const url = `${ENV_CONFIG.API_ROOT}/registerclosures/${id}`;
    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 0) {
          throw new Error('Register Closure not found');
        }

        return RegisterClosure.parse(data[0]);
      })
    );
  }
  public deleteRegisterClosure(registerClosure: RegisterClosure) : Observable<RegisterClosure> {
    const url = `${ENV_CONFIG.API_ROOT}/registerclosures/${registerClosure.id}`;
    return this.delete(url, {});
  }
  
  public loadCurrentValues(
    registerClosureEndDateTime?: Date,
    dateOpened?: Date
  ): Observable<RegisterClosure> {
    let url = `${ENV_CONFIG.API_ROOT}/registerclosures/currentvalues`;

    if (registerClosureEndDateTime) {
      url +=
        `?registerClosureEndDateTime=` +
        Utilities.formatDate(registerClosureEndDateTime);
      if (dateOpened) {
        url +=
          `&dateOpened=` +
          Utilities.formatDate(dateOpened);
      }
    }

    return this.get(url).pipe(
      map((data) => {
        if (data && data.length === 0) {
          throw new Error('Register Closure not found');
        }

        return RegisterClosure.parse(data[0]);
      })
    );
  }

  public save(registerClosure: RegisterClosure): Observable<RegisterClosure> {
    let url = `${ENV_CONFIG.API_ROOT}/registerclosures`;
    url += registerClosure.id ? `/${registerClosure.id}` : '';

    if (registerClosure.id) {
      return this.put(url, registerClosure.serialize()).pipe(
        map((data) => {
          return RegisterClosure.parse(data);
        })
      );
    } else {
      return this.post(url, registerClosure.serialize()).pipe(
        map((data) => {
          return RegisterClosure.parse(data);
        })
      );
    }
  }
}
