import { Cloneable } from './cloneable';

import { Serializable } from './serializable';
import { fromJS, List, Record } from 'immutable';

export interface GiftCardSettingJSON {
  salonID: number;
  sellEGiftCardsOnline: boolean;
  minLoad: number;
  maxLoad: number;
  suggestedValue1: number;
  suggestedValue2: number;
  suggestedValue3: number;
  suggestedValue4: number;
  suggestedValue5: number;
}

export interface GiftCardSettingSerialized {
  salonID: number;
  sellEGiftCardsOnline: boolean;
  minLoad: number;
  maxLoad: number;
  suggestedValue1: number;
  suggestedValue2: number;
  suggestedValue3: number;
  suggestedValue4: number;
  suggestedValue5: number;
}

export class GiftCardSetting
  implements
    Cloneable<GiftCardSetting, GiftCardSettingJSON>,
    Serializable<GiftCardSettingSerialized>
{
  public readonly salonID: number;
  public sellEGiftCardsOnline: boolean;
  public minLoad: number;
  public maxLoad: number;
  public suggestedValue1: number;
  public suggestedValue2: number;
  public suggestedValue3: number;
  public suggestedValue4: number;
  public suggestedValue5: number;

  constructor(options: GiftCardSettingJSON) {
    this.salonID = options.salonID;
    this.sellEGiftCardsOnline = options.sellEGiftCardsOnline;
    this.minLoad = options.minLoad;
    this.maxLoad = options.maxLoad;
    this.suggestedValue1 = options.suggestedValue1;
    this.suggestedValue2 = options.suggestedValue2;
    this.suggestedValue3 = options.suggestedValue3;
    this.suggestedValue4 = options.suggestedValue4;
    this.suggestedValue5 = options.suggestedValue5;
  }

  public static parse(params: Object): GiftCardSetting {
    return new GiftCardSetting({
      salonID: params['salonID'],
      sellEGiftCardsOnline: params['sellEGiftCardsOnline'],
      minLoad: params['minLoad'],
      maxLoad: params['maxLoad'],
      suggestedValue1: params['suggestedValue1'],
      suggestedValue2: params['suggestedValue2'],
      suggestedValue3: params['suggestedValue3'],
      suggestedValue4: params['suggestedValue4'],
      suggestedValue5: params['suggestedValue5'],
    });
  }

  public toJSON(): GiftCardSettingJSON {
    return {
      salonID: this.salonID,
      sellEGiftCardsOnline: this.sellEGiftCardsOnline,
      minLoad: this.minLoad,
      maxLoad: this.maxLoad,
      suggestedValue1: this.suggestedValue1,
      suggestedValue2: this.suggestedValue2,
      suggestedValue3: this.suggestedValue3,
      suggestedValue4: this.suggestedValue4,
      suggestedValue5: this.suggestedValue5,
    };
  }

  public clone(): GiftCardSetting {
    return new GiftCardSetting(this.toJSON());
  }

  public serialize(): GiftCardSettingSerialized {
    return {
      salonID: this.salonID,
      sellEGiftCardsOnline: this.sellEGiftCardsOnline,
      minLoad: this.minLoad,
      maxLoad: this.maxLoad,
      suggestedValue1: this.suggestedValue1,
      suggestedValue2: this.suggestedValue2,
      suggestedValue3: this.suggestedValue3,
      suggestedValue4: this.suggestedValue4,
      suggestedValue5: this.suggestedValue5,
    };
  }
}
